export enum PermissionConsts {
    // user section
    User_Create = 'User.Create',
    User_Deactivate = 'User.Deactivate',
    User_Delete = 'User.Delete',
    User_Edit = 'User.Edit',
    User_ForceResetPassword = 'User.ForceResetPassword',
    User_RequestEmailConfirmation = 'User.RequestEmailConfirmation',
    User_UnlockLogin = 'User.UnlockLogin',
    User_UpdateMyProfile = 'User.UpdateMyProfile',
    User_GetMyProfile = 'User.GetMyProfile',

    // all pages
    Pages_Roles = 'Pages.Roles',
    Pages_Tenants = 'Pages.Tenants',
    Pages_Users = 'Pages.Users',
    Pages_Users_Activation = 'Pages.Users.Activation',

    // center section
    Center_Create = 'Center.Create',
    Center_Delete = 'Center.Delete',
    Center_Detail = 'Center.Detail',
    Center_Export = 'Center.Export',
    Center_List = 'Center.List',
    Center_Update = 'Center.Update',

    // file section
    File_Create = 'File.Create',
    File_Delete = 'File.Delete',
    File_Detail = 'File.Detail',
    File_Export = 'File.Export',
    File_List = 'File.List',

    // meeting section
    Meeting_Create = 'Meeting.Create',
    Meeting_Delete = 'Meeting.Delete',
    Meeting_Get = 'Meeting.Get',
    Meeting_GetAll = 'Meeting.GetAll',
    Meeting_Update = 'Meeting.Update',

    // multipleChoice exercise section
    MultipleChoiceExercise_Create = 'MultipleChoiceExercise.Create',
    MultipleChoiceExercise_Delete = 'MultipleChoiceExercise.Delete',
    MultipleChoiceExercise_Get = 'MultipleChoiceExercise.Get',
    MultipleChoiceExercise_GetAll = 'MultipleChoiceExercise.GetAll',
    MultipleChoiceExercise_Update = 'MultipleChoiceExercise.Update',

    // quiz section
    Quiz_Create = 'Quiz.Create',
    Quiz_Delete = 'Quiz.Delete',
    Quiz_Get = 'Quiz.Get',
    Quiz_GetAll = 'Quiz.GetAll',
    Quiz_Resolve = 'Quiz.Resolve',
    Quiz_Update = 'Quiz.Update',

    // quiz user relation section
    QuizUsers_Create = 'QuizUsers.Create',
    QuizUsers_Delete = 'QuizUsers.Delete',
    QuizUsers_Get = 'QuizUsers.Get',
    QuizUsers_GetAll = 'QuizUsers.GetAll',
    QuizUsers_Update = 'QuizUsers.Update',

    // request help section
    RequestHelp_Create = 'RequestHelp.Create',
    RequestHelp_Delete = 'RequestHelp.Delete',
    RequestHelp_Get = 'RequestHelp.Get',
    RequestHelp_GetAll = 'RequestHelp.GetAll',
    RequestHelp_Update = 'RequestHelp.Update',

    // sentence ordering exercise section
    SentenceOrderingExercise_Create = 'SentenceOrderingExercise.Create',
    SentenceOrderingExercise_Delete = 'SentenceOrderingExercise.Delete',
    SentenceOrderingExercise_Get = 'SentenceOrderingExercise.Get',
    SentenceOrderingExercise_GetAll = 'SentenceOrderingExercise.GetAll',
    SentenceOrderingExercise_Update = 'SentenceOrderingExercise.Update',

    // teaching material section
    TeachingMaterial_Create = 'TeachingMaterial.Create',
    TeachingMaterial_Delete = 'TeachingMaterial.Delete',
    TeachingMaterial_Detail = 'TeachingMaterial.Detail',
    TeachingMaterial_Export = 'TeachingMaterial.Export',
    TeachingMaterial_List = 'TeachingMaterial.List',
    TeachingMaterial_Update = 'TeachingMaterial.Update',

    // teaching material user relation section
    TeachingMaterialUser_Create = 'TeachingMaterialUser.Create',
    TeachingMaterialUser_Delete = 'TeachingMaterialUser.Delete',
    TeachingMaterialUser_Detail = 'TeachingMaterialUser.Detail',
    TeachingMaterialUser_Export = 'TeachingMaterialUser.Export',
    TeachingMaterialUser_List = 'TeachingMaterialUser.List',
    TeachingMaterialUser_Update = 'TeachingMaterialUser.Update',
}
