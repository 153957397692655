import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { validTenantGuard } from '@shared/routing/valid-tenant.guard';

const routes: Routes = [
    { path: '', redirectTo: '/app/home', pathMatch: 'full' },
    {
        path: 'account',
        canActivate: [validTenantGuard],
        loadChildren: () => import('account/account.module').then((m) => m.AccountModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'app',
        canActivate: [validTenantGuard],
        loadChildren: () => import('app/app.module').then((m) => m.AppModule), // Lazy load account module
        data: { preload: true },
    },
    {
        path: 'fallback',
        loadChildren: () => import('./fallback/fallback.module').then((m) => m.FallbackModule), // Lazy load fallback route
        data: { preload: true },
    },
    {
        path: '**',
        redirectTo: 'fallback',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            bindToComponentInputs: true, // Enables route params to be accessed via @Input decorator in components
            scrollPositionRestoration: 'enabled', // Resets scroll on route change
        }),
    ],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {}
