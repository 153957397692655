import { AccountServiceProxy, IsTenantAvailableInput, IsTenantAvailableOutput } from '@shared/service-proxies/service-proxies';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class MultiTenancyService {
    constructor(private accountServiceProxy: AccountServiceProxy) {}

    getTenantId(): Observable<IsTenantAvailableOutput> {
        const tenancyName = window.location.hostname.split('.')[0];
        return this.accountServiceProxy.isTenantAvailable(
            new IsTenantAvailableInput({
                tenancyName,
            }),
        );
    }
}
